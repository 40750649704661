import React, { ReactElement } from "react";
import VideoEmbed, { VideoEmbedProps } from "../components/VideoEmbed";

const Watch = (): ReactElement => {
  const videos: VideoEmbedProps[] = [
    {
      title: "Cold Snap",
      url: "https://www.youtube.com/embed/61zX6G_XDXg?si=JxiHh8Haqj9FLwpr",
    },
    {
      title: "Here",
      url: "https://www.youtube.com/embed/rV031ZUtP1M?si=eHL3sGNWfVqhkDjE",
    },
    {
      title: "Now",
      url: "https://www.youtube.com/embed/XE_ZcXzDLHA?si=njRnX5jpiXzT4JwX",
    },
    {
      title: "Lucky",
      url: "https://www.youtube.com/embed/ghl4CQTC2tw?si=ZKcP-i3WXxzuk8Ar",
    },
    // {
    //   title: "Hum",
    //   url: "https://www.youtube.com/embed/TqDHU6SHFbY?si=h8lueZPL1sHfC8yO",
    // },
    // {
    //   title: "Ambient Patch 3",
    //   url: "https://www.youtube.com/embed/Oq9eY_fOT0A",
    // },
  ];

  return (
    <div className="page-container">
      {/* <YoutubeButton /> */}
      <div className="media-list-flex">
        {videos.map((props: VideoEmbedProps) => (
          <VideoEmbed
            caption={props.caption}
            key={props.title}
            title={props.title}
            url={props.url}
          />
        ))}
      </div>
    </div>
  );
};

export default Watch;
